/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

//==============================================================
// START Enums and Input Objects
//==============================================================

export enum AccountFeeType {
  ACCOUNT_FEE = "ACCOUNT_FEE",
  ACCOUNT_FEE_MARCH_2023 = "ACCOUNT_FEE_MARCH_2023",
}

export enum ApplySaverReferralCodeResult {
  ALREADY_APPLIED = "ALREADY_APPLIED",
  BANNED = "BANNED",
  EXPIRED = "EXPIRED",
  INVALID = "INVALID",
  SUCCESS = "SUCCESS",
}

export enum BasiqConnectionStatus {
  CONNECTED = "CONNECTED",
  CONNECTING = "CONNECTING",
  DISCONNECTED = "DISCONNECTED",
  REMOVED = "REMOVED",
}

export enum BiometricVerificationStatus {
  FAILED = "FAILED",
  IN_PROGRESS = "IN_PROGRESS",
  UNVERIFIED = "UNVERIFIED",
  VERIFIED = "VERIFIED",
}

export enum BoostItemGroupStatus {
  CANCELLED = "CANCELLED",
  LOCKED = "LOCKED",
  PENDING_TRANSACTION = "PENDING_TRANSACTION",
  TRANSACTION_CREATED = "TRANSACTION_CREATED",
  UNLOCKED = "UNLOCKED",
}

export enum BoostRecipeParameterType {
  AUD_AMOUNT = "AUD_AMOUNT",
}

export enum BoostRecipeStatus {
  ACTIVE = "ACTIVE",
  DELETED = "DELETED",
  INACTIVE = "INACTIVE",
}

export enum BoostSourceType {
  BASIQ_CONNECTION = "BASIQ_CONNECTION",
  STRAVA = "STRAVA",
  WEATHER = "WEATHER",
}

export enum ContactStatus {
  ACTIVE = "ACTIVE",
  BLOCKED = "BLOCKED",
  CLOSED = "CLOSED",
  PENDING = "PENDING",
}

export enum DocumentType {
  NOVA_ACCOUNT_STATEMENT = "NOVA_ACCOUNT_STATEMENT",
  NOVA_TRADE_CONFIRMATION = "NOVA_TRADE_CONFIRMATION",
}

export enum FHSSStatus {
  NOT_SPECIFIED = "NOT_SPECIFIED",
  OPTED_IN = "OPTED_IN",
  OPTED_OUT = "OPTED_OUT",
}

export enum Gender {
  FEMALE = "FEMALE",
  MALE = "MALE",
  UNSPECIFIED = "UNSPECIFIED",
}

export enum MarketStatus {
  CLOSED = "CLOSED",
  OPEN = "OPEN",
}

export enum MfaVerificationType {
  EMAIL = "EMAIL",
  MANUAL = "MANUAL",
  SMS = "SMS",
  WHATSAPP = "WHATSAPP",
}

export enum MissingTINReason {
  COUNTRY_DOES_NOT_PROVIDE_TIN = "COUNTRY_DOES_NOT_PROVIDE_TIN",
  OTHER = "OTHER",
  TIN_YET_TO_BE_ISSUED = "TIN_YET_TO_BE_ISSUED",
}

/**
 * @deprecated(reason: "use MoneyPaymentSource")
 */
export enum MoneyDepositSource {
  DIRECT_ENTRY = "DIRECT_ENTRY",
  PAYTO = "PAYTO",
}

export enum MoneyPaymentSource {
  DIRECT_ENTRY = "DIRECT_ENTRY",
  NPP = "NPP",
  PAYTO = "PAYTO",
}

export enum MoneyTransactionStatus {
  CANCELLED = "CANCELLED",
  COMPLETED = "COMPLETED",
  FAILED = "FAILED",
  PENDING = "PENDING",
  PROCESSING = "PROCESSING",
}

export enum MoneyTransactionType {
  BOOST = "BOOST",
  DISTRIBUTION = "DISTRIBUTION",
  DIVIDEND = "DIVIDEND",
  FEE = "FEE",
  MERGER_ACQUISITION = "MERGER_ACQUISITION",
  MONEY_IN = "MONEY_IN",
  MONEY_OUT = "MONEY_OUT",
  PURCHASE = "PURCHASE",
  SALE = "SALE",
}

export enum NovaAccountApplicationEmploymentStatus {
  EMPLOYED = "EMPLOYED",
  RETIRED = "RETIRED",
  STUDENT = "STUDENT",
  UNEMPLOYED = "UNEMPLOYED",
}

export enum NovaAccountApplicationFundingSource {
  BUSINESS_INCOME = "BUSINESS_INCOME",
  EMPLOYMENT_INCOME = "EMPLOYMENT_INCOME",
  FAMILY = "FAMILY",
  INHERITANCE = "INHERITANCE",
  INVESTMENTS = "INVESTMENTS",
  SAVINGS = "SAVINGS",
}

export enum NovaAccountApplicationLiquidNetWorthBand {
  AUD_0_49999 = "AUD_0_49999",
  AUD_1000000_PLUS = "AUD_1000000_PLUS",
  AUD_100000_499999 = "AUD_100000_499999",
  AUD_500000_999999 = "AUD_500000_999999",
  AUD_50000_99999 = "AUD_50000_99999",
}

export enum NovaAccountApplicationStatus {
  BANK_ACCOUNT_REQUIRED = "BANK_ACCOUNT_REQUIRED",
  FORM_REQUIRED = "FORM_REQUIRED",
  INELIGIBLE = "INELIGIBLE",
  KYC_REQUIRED = "KYC_REQUIRED",
  MFA_REQUIRED = "MFA_REQUIRED",
  PENDING = "PENDING",
  TFN_REQUIRED = "TFN_REQUIRED",
}

export enum NovaAssetActivityStatus {
  HAS_HOLDINGS = "HAS_HOLDINGS",
  HAS_INVESTMENT_PLAN = "HAS_INVESTMENT_PLAN",
  HAS_INVESTMENT_PLAN_AND_TRANSACTION = "HAS_INVESTMENT_PLAN_AND_TRANSACTION",
  HAS_TRANSACTION = "HAS_TRANSACTION",
  NO_ACTIVITY = "NO_ACTIVITY",
}

export enum NovaAssetGroup {
  ETF = "ETF",
  STOCK = "STOCK",
}

export enum NovaAssetSortBy {
  ALPHA_AZ = "ALPHA_AZ",
  ALPHA_ZA = "ALPHA_ZA",
  CHANGE_ONE_YEAR_HIGH_TO_LOW = "CHANGE_ONE_YEAR_HIGH_TO_LOW",
  CHANGE_ONE_YEAR_LOW_TO_HIGH = "CHANGE_ONE_YEAR_LOW_TO_HIGH",
  MOST_INVESTED = "MOST_INVESTED",
}

export enum NovaRegulatoryFeeType {
  SEC = "SEC",
  TAF = "TAF",
}

export enum NovaScheduleFrequency {
  FORTNIGHTLY = "FORTNIGHTLY",
  MONTHLY = "MONTHLY",
  WEEKLY = "WEEKLY",
}

export enum NovaTransactionStatus {
  COMPLETED = "COMPLETED",
  FAILED = "FAILED",
  HOLD = "HOLD",
  IN_MARKET = "IN_MARKET",
  ORDERED = "ORDERED",
  PROCESSING = "PROCESSING",
  SUBMITTED = "SUBMITTED",
}

export enum NovaTransactionTypeFilter {
  ACCOUNT_FEE = "ACCOUNT_FEE",
  BUY = "BUY",
  DIVIDEND = "DIVIDEND",
  REGULATORY_FEE = "REGULATORY_FEE",
  SELL = "SELL",
}

export enum OCRTransactionReason {
  CHANGE_BANK_ACCOUNT = "CHANGE_BANK_ACCOUNT",
  MERGE_VOYAGER_ACCOUNT = "MERGE_VOYAGER_ACCOUNT",
}

export enum OnboardingStatus {
  SUPER = "SUPER",
  VOYAGER = "VOYAGER",
}

export enum PayToAgreementStatus {
  ACTIVE = "ACTIVE",
  CANCELLED = "CANCELLED",
  CREATED = "CREATED",
  DECLINED = "DECLINED",
  EXPIRED = "EXPIRED",
  FAILED = "FAILED",
  PENDING = "PENDING",
  SUSPENDED = "SUSPENDED",
}

export enum PaymentMethod {
  DIRECT_ENTRY = "DIRECT_ENTRY",
  PAY_ID = "PAY_ID",
}

export enum ProductOffering {
  NOVA = "NOVA",
  SUPER = "SUPER",
  VOYAGER = "VOYAGER",
}

export enum SaverAssetGroup {
  ETF = "ETF",
  STOCK = "STOCK",
}

export enum SaverPortfolio {
  EARTH = "EARTH",
  EXPLORER = "EXPLORER",
  GALAXY = "GALAXY",
  INDEX = "INDEX",
  UNIVERSE = "UNIVERSE",
}

export enum SaverReferralReceiveStatus {
  AWAITING_PAYMENT = "AWAITING_PAYMENT",
  ELIGIBLE = "ELIGIBLE",
  FINALISED = "FINALISED",
  INELIGIBLE = "INELIGIBLE",
  PENDING_FINALISATION = "PENDING_FINALISATION",
}

export enum SaverReferralShareStatus {
  ELIGIBLE = "ELIGIBLE",
  INELIGIBLE = "INELIGIBLE",
}

export enum SaverTMDFundsAllocation {
  FIFTY_TO_HUNDRED_PERCENTAGE = "FIFTY_TO_HUNDRED_PERCENTAGE",
  FIFTY_TO_SEVENTY_FIVE_PERCENTAGE = "FIFTY_TO_SEVENTY_FIVE_PERCENTAGE",
  HIGH_PERCENTAGE = "HIGH_PERCENTAGE",
  LOW_PERCENTAGE = "LOW_PERCENTAGE",
  MEDIUM_PERCENTAGE = "MEDIUM_PERCENTAGE",
  SEVENTY_FIVE_TO_HUNDRED_PERCENTAGE = "SEVENTY_FIVE_TO_HUNDRED_PERCENTAGE",
  TWENTY_FIVE_TO_FIFTY_PERCENTAGE = "TWENTY_FIVE_TO_FIFTY_PERCENTAGE",
  UP_TO_TWENTY_FIVE_PERCENTAGE = "UP_TO_TWENTY_FIVE_PERCENTAGE",
}

export enum SaverTMDInvestmentGoal {
  HIGHER_RISK_LONG_TERM = "HIGHER_RISK_LONG_TERM",
  LOWER_RISK_SHORT_TERM = "LOWER_RISK_SHORT_TERM",
  MEDIUM_RISK_MEDIUM_TERM = "MEDIUM_RISK_MEDIUM_TERM",
  NONE_APPLY = "NONE_APPLY",
}

export enum SaverTMDResult {
  MANUALLY_APPROVED = "MANUALLY_APPROVED",
  MANUALLY_REJECTED = "MANUALLY_REJECTED",
  NOT_OK = "NOT_OK",
  OK = "OK",
  REQUIRES_REVIEW = "REQUIRES_REVIEW",
}

export enum SaverTMDWithdrawalFrequency {
  DAILY = "DAILY",
  WEEKLY_OR_LONGER = "WEEKLY_OR_LONGER",
}

export enum SaverTMDWithdrawalFrequencyDailySubQuestion {
  NO = "NO",
  YES = "YES",
}

export enum ScheduleFrequency {
  FORTNIGHTLY = "FORTNIGHTLY",
  MONTHLY = "MONTHLY",
  ONE_TIME = "ONE_TIME",
  WEEKLY = "WEEKLY",
}

export enum Scope {
  ACCOUNT_READ = "ACCOUNT_READ",
  ACCOUNT_VERIFIED = "ACCOUNT_VERIFIED",
  ACCOUNT_WRITE = "ACCOUNT_WRITE",
  BASIC_READ = "BASIC_READ",
  NOVA = "NOVA",
  SARGON_READ_WRITE = "SARGON_READ_WRITE",
  SAVER_READ = "SAVER_READ",
  SAVER_WRITE = "SAVER_WRITE",
  SUPER_READ = "SUPER_READ",
  SUPER_VERIFIED = "SUPER_VERIFIED",
}

export enum SuperJoinType {
  BOOKIPI = "BOOKIPI",
  EMPLOYMENT_HERO = "EMPLOYMENT_HERO",
  HARD_LINK = "HARD_LINK",
  HARD_LINK_WEB = "HARD_LINK_WEB",
  MIGRATED_FROM_WEB = "MIGRATED_FROM_WEB",
  SIGN_UP = "SIGN_UP",
  SIGN_UP_WEB = "SIGN_UP_WEB",
  SOFT_LINK = "SOFT_LINK",
}

export enum SuperPortfolio {
  GLOBAL_INDEX = "GLOBAL_INDEX",
  GROWTHX = "GROWTHX",
}

export enum SuperTMDInvestmentGoal {
  HIGHER_RISK = "HIGHER_RISK",
  LOWER_RISK = "LOWER_RISK",
  MEDIUM_RISK = "MEDIUM_RISK",
  NONE_APPLY = "NONE_APPLY",
}

export enum SuperTMDInvestmentGoalSubQuestion {
  NO = "NO",
  YES = "YES",
}

export enum SuperTMDResult {
  NOT_OK = "NOT_OK",
  OK = "OK",
}

export enum SuperTMDWithdrawalTimeframe {
  NOT_APPLICABLE = "NOT_APPLICABLE",
  NOT_WITHIN_10_YEARS = "NOT_WITHIN_10_YEARS",
  WITHIN_10_YEARS = "WITHIN_10_YEARS",
}

export enum TFNExemptionCode {
  BUSINESS_INVESTOR = "BUSINESS_INVESTOR",
  CENTRELINK = "CENTRELINK",
  ENTITY_NOT_REQUIRED = "ENTITY_NOT_REQUIRED",
  NON_RESIDENT = "NON_RESIDENT",
  NOT_AN_ABN = "NOT_AN_ABN",
  PENSIONER = "PENSIONER",
  UNDER_16 = "UNDER_16",
}

export enum TMDInvestmentGoalComfortLevel {
  HIGH_TOLERANCE_FOR_RISK = "HIGH_TOLERANCE_FOR_RISK",
  OTHER = "OTHER",
  SPOKEN_TO_FINANCIAL_ADVISOR = "SPOKEN_TO_FINANCIAL_ADVISOR",
}

export enum TaxGroup {
  AUSTRALIA_AND_OVERSEAS = "AUSTRALIA_AND_OVERSEAS",
  AUSTRALIA_ONLY = "AUSTRALIA_ONLY",
  OVERSEAS_ONLY = "OVERSEAS_ONLY",
  UNSPECIFIED = "UNSPECIFIED",
}

export enum TaxResidenceStatus {
  SPECIFIED = "SPECIFIED",
  UNSPECIFIED = "UNSPECIFIED",
}

export enum TimePeriod {
  MAX = "MAX",
  ONE_MONTH = "ONE_MONTH",
  ONE_YEAR = "ONE_YEAR",
  SIX_MONTHS = "SIX_MONTHS",
  THREE_MONTHS = "THREE_MONTHS",
}

export enum TransactionStatus {
  CANCELLED = "CANCELLED",
  FAILED = "FAILED",
  PAID = "PAID",
  PENDING = "PENDING",
  REJECTED = "REJECTED",
  TRANSFERRING = "TRANSFERRING",
}

export enum VerificationStatus {
  FAILED = "FAILED",
  IN_REVIEW = "IN_REVIEW",
  PASSED = "PASSED",
}

export enum VerifyPasswordResetOutcome {
  EXPIRED = "EXPIRED",
  MISMATCH = "MISMATCH",
  SUCCESS = "SUCCESS",
  USED = "USED",
}

export interface AddBankAccountInput {
  bsb: string;
  accountNumber: string;
  accountName: string;
  friendlyName?: string | null;
}

export interface AddForeignTaxResidencesInput {
  residences: CreateForeignTaxResidence[];
}

export interface AddProductIntentInput {
  product: ProductOffering;
}

export interface AddressInput {
  country: string;
  postcode: string;
  state: string;
  suburb: string;
  streetName: string;
  streetNumber: string;
  streetType: string;
  unitNumber?: string | null;
  taxReason?: string | null;
}

export interface ApplySaverReferralCodeInput {
  code: string;
}

export interface BindingBeneficiaryDetailsInput {
  givenName?: string | null;
  familyName?: string | null;
  relationship?: string | null;
  dateOfBirth?: string | null;
  streetLine1?: string | null;
  streetLine2?: string | null;
  suburb?: string | null;
  state?: string | null;
  postcode?: string | null;
  countryCode?: string | null;
  proportion?: string | null;
}

export interface BoostRecipeInputParameter {
  name: string;
  value: string;
  type: BoostRecipeParameterType;
}

export interface CancelApplicationInput {
  id: string;
}

export interface CancelBoostItemGroupInput {
  id: string;
}

export interface CancelNovaInvestmentPlanInput {
  id: string;
}

export interface CancelPaymentSchedulesForProductInstancesInput {
  productIds: string[];
}

export interface CheckMfaVerificationInput {
  answer: MfaVerificationAnswer;
}

export interface CloseSaverProductInstancesInput {
  productIds: string[];
  reason?: string | null;
}

export interface CountryFilters {
  taxResidencyAllowed?: boolean | null;
}

export interface CreateBasiqConnectionInput {
  institutionId: string;
  username: string;
  secondaryUsername?: string | null;
  password: string;
  securityCode?: string | null;
}

export interface CreateBindingBeneficiaryIntentInput {
  id: string;
  beneficiaries?: BindingBeneficiaryDetailsInput[] | null;
}

export interface CreateBoostRecipeInput {
  saverProductInstanceId: string;
  templateId: string;
  sourceId: string;
  parameters?: BoostRecipeInputParameter[] | null;
}

export interface CreateForeignTaxResidence {
  countryId: string;
  primaryResidence: boolean;
  missingTINOtherReasonDescription?: string | null;
  missingTINReason?: MissingTINReason | null;
  tin?: string | null;
}

export interface CreateMoneyDepositInput {
  audAmount: string;
  source: MoneyDepositSource;
  paymentSource: MoneyPaymentSource;
}

export interface CreateMoneyWithdrawalInput {
  audAmount: string;
}

export interface CreatePayToAgreementInput {
  maxAudAmount?: string | null;
}

export interface CreatePaymentScheduleInput {
  productId: string;
  frequency: ScheduleFrequency;
  audAmount: string;
  startDate?: string | null;
}

export interface CreateRedemptionInput {
  productId: string;
  audAmount?: string | null;
  fullRedemption?: boolean | null;
}

export interface CreateSargonMemberInput {
  tfn?: string | null;
  gender: Gender;
  superJoinType: SuperJoinType;
}

export interface CreateSaverInvestmentGoalInput {
  name: string;
  targetAudAmount: string;
  emoji: string;
}

export interface CreateSaverProductInstancesInput {
  portfolios: SaverPortfolio[];
}

export interface DateRange {
  from: string;
  to: string;
}

export interface DeleteForeignTaxResidencesInput {
  ids?: string[] | null;
}

export interface DeleteSaverInvestmentGoalInput {
  id: string;
}

export interface DeleteTfnExemptionInput {
  productId: string;
}

export interface DeleteTfnInput {
  productId: string;
}

export interface DismissInput {
  id: string;
}

export interface GenerateMultiMonthStatementInput {
  startDate: string;
  endDate: string;
}

export interface InitiateBiometricVerificationInput {
  redirectUrlSuccess?: string | null;
  redirectUrlExit?: string | null;
  redirectUrlBack?: string | null;
  reason: OCRTransactionReason;
}

export interface InitiateMfaVerificationInput {
  type: MfaVerificationType;
  to?: string | null;
}

export interface LinkWithSargonInput {
  auth: string;
  superJoinType?: SuperJoinType | null;
}

export interface LoginInput {
  email: string;
  password: string;
  mfaAnswer?: MfaVerificationAnswer | null;
  mfaVerificationType?: MfaVerificationType | null;
}

export interface MfaVerificationAnswer {
  id?: string | null;
  token: string;
}

export interface NovaAccountApplicationFieldsInput {
  countryOfCitizenship3LetterCode?: string | null;
  countryOfTaxResidence3LetterCode?: string | null;
  fundingSource?: NovaAccountApplicationFundingSource | null;
  taxFileNumber?: string | null;
  liquidNetWorthBand?: NovaAccountApplicationLiquidNetWorthBand | null;
  isControlledPerson?: boolean | null;
  isAffiliatedExchangeOrFinra?: boolean | null;
  isPoliticallyExposed?: boolean | null;
  employmentStatus?: NovaAccountApplicationEmploymentStatus | null;
  employerName?: string | null;
  employerAddress?: string | null;
  employmentPosition?: string | null;
  acknowledgementsSigned?: boolean | null;
}

export interface NovaBuyOrderPreviewInput {
  assetID: string;
  audAmount: string;
}

export interface NovaCancelOrderInput {
  transactionID: string;
}

export interface NovaPlaceOrderInput {
  previewID: string;
  paymentMethod?: PaymentMethod | null;
}

export interface NovaSellOrderPreviewInput {
  assetID: string;
  units: string;
}

export interface NovaTrustedContactInput {
  firstName: string;
  lastName: string;
  emailAddress: string;
  phoneNumber: string;
}

export interface ReconnectBasiqConnectionInput {
  id: string;
  username: string;
  password: string;
  secondaryUsername?: string | null;
  securityCode?: string | null;
}

export interface RefreshTokenInput {
  refreshToken?: string | null;
}

export interface RemoveBasiqConnectionInput {
  id: string;
}

export interface RevokeSessionInput {
  id: string;
}

export interface SendEmployerContributionEmailInput {
  employerEmailAddress?: string | null;
}

export interface SendPasswordResetCodeInput {
  email: string;
}

export interface SendSaverAccountStatementInput {
  dateRange: DateRange;
  productId: string;
}

export interface SetForeignTaxResidenceInput {
  id?: string | null;
  countryId: string;
  missingTINOtherReasonDescription?: string | null;
  missingTINReason?: MissingTINReason | null;
  tin?: string | null;
}

export interface SetNovaInvestmentPlanInput {
  assetID: string;
  frequency: NovaScheduleFrequency;
  audAmount: string;
  startDate: string;
}

export interface SetPrimarySaverProductInstanceInput {
  id: string;
}

export interface SetSaverTargetMarketDeterminationAnswersInput {
  portfolio: SaverPortfolio;
  questionsVersion: string;
  investmentGoal: SaverTMDInvestmentGoal;
  fundsAllocation: SaverTMDFundsAllocation;
  fundsAllocationMediumSubQuestion?: string | null;
  withdrawalFrequency: SaverTMDWithdrawalFrequency;
  withdrawalFrequencyDailySubQuestion?: SaverTMDWithdrawalFrequencyDailySubQuestion | null;
  resubmissionConfirmation?: string | null;
  comfortLevel?: TMDInvestmentGoalComfortLevel | null;
  comfortLevelOtherReason?: string | null;
}

export interface SetSuperTargetMarketDeterminationAnswersInput {
  portfolio: SuperPortfolio;
  questionsVersion: string;
  withdrawalTimeframe: SuperTMDWithdrawalTimeframe;
  investmentGoal: SuperTMDInvestmentGoal;
  investmentGoalSubQuestion?: SuperTMDInvestmentGoalSubQuestion | null;
}

export interface SetTaxResidencyInput {
  taxGroup: TaxGroup;
  address?: AddressInput | null;
  foreignTaxResidences?: SetForeignTaxResidenceInput[] | null;
}

export interface SubmitPreApprovedTargetMarketDeterminationInput {
  portfolio: SaverPortfolio;
  resubmissionConfirmation?: string | null;
}

export interface TopUpAndCloseBoostItemGroupInput {
  id: string;
}

export interface UpdateBoostRecipeInput {
  id: string;
  status?: BoostRecipeStatus | null;
  sourceId?: string | null;
  parameters?: BoostRecipeInputParameter[] | null;
}

export interface UpdateContactInput {
  phoneNumber?: string | null;
  email?: string | null;
  address?: AddressInput | null;
  taxResidenceStatus?: TaxResidenceStatus | null;
  preferredName?: string | null;
  taxGroup?: TaxGroup | null;
}

export interface UpdateFHSSStatusInput {
  status: FHSSStatus;
}

export interface UpdateForeignTaxResidence {
  id: string;
  primaryResidence?: boolean | null;
  missingTINOtherReasonDescription?: string | null;
  missingTINReason?: MissingTINReason | null;
  tin?: string | null;
}

export interface UpdateForeignTaxResidencesInput {
  residences: UpdateForeignTaxResidence[];
}

export interface UpdatePasswordByCurrentPasswordInput {
  currentPassword: string;
  newPassword: string;
}

export interface UpdatePasswordInput {
  resetId: string;
  newPassword: string;
}

export interface UpdatePayToAgreementInput {
  maxAudAmount?: string | null;
}

export interface UpdateSaverInvestmentGoalInput {
  id: string;
  name?: string | null;
  targetAudAmount?: string | null;
  emoji?: string | null;
}

export interface UpdateTfnExemptionInput {
  productId: string;
  tfnExemptionCode: TFNExemptionCode;
}

export interface UpdateTfnInput {
  productId: string;
  tfn: string;
}

export interface ValidateTINInput {
  countryId: string;
  tin: string;
}

export interface VerifyPasswordResetCodeInput {
  resetId: string;
  code: string;
}

//==============================================================
// END Enums and Input Objects
//==============================================================
